import { SupplierAddress } from './supplier-address';
import { SupplierBasic } from './supplier-basic';
import { SupplierCategory } from './supplier-category';
import { SupplierCommunicationChannel } from './supplier-communication-channel';

export interface SupplierBase extends SupplierBasic {
  placeOfBusiness?: string;
  vatId?: string;
  commercialRegisterNumber?: string;
  registryCourt?: string;
  gln?: string;
}

export interface Supplier extends SupplierBase {
  addresses?: Array<SupplierAddress>;
  communicationChannels?: SupplierCommunicationChannel;
}

// TODO: EnumDescriptions so ähnlich wie im Import
export const supplierCategoryDescriptions: Readonly<Record<SupplierCategory, string>> = {
  [SupplierCategory.ParentOrganization]: 'Dachorganisation',
  [SupplierCategory.Supplier]: 'Lieferant',
  [SupplierCategory.Manufacturer]: 'Hersteller',
  [SupplierCategory.Division]: 'Division',
};
